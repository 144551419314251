import { request } from "@/request/http";

/**
 * @name: 工作流水接口
 * @author: LXY
 * @date: 2022-02-28 
 */
export default {
    // 获取工作流水列表
    getTaskDetailList: (data) => {
        return request("POST", `/index.php/adminTask/getTaskDetailList`, data);
    },
    // 删除任务详情
    deleteTaskDetail: (data) => {
        return request("POST", `/index.php/adminTask/deleteTaskDetail`, data);
    },
    // 导入任务详情
    importTaskDetail: (data) => {
        return request("POST", `/index.php/adminTask/importTaskDetail`, data);
    },
};