import { encryptCode, decryptCode, changeObjectkey } from "@/utils/function";
import { request_sign } from '@/utils/const';
import API from '@/api/manager/task/taskDetail.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "taskDetail",
    data() {
        return {
            // 未成功导入数据弹窗
            failDataDialog: false,
            // 工作流水列表
            taskDetailList: [],
            // 教师名称搜索值
            search: '',
            // 当前页码数
            page: 1,
            // 当前一页显示多少条数据
            pageSize: 10,
            // 数据总条数
            total: 0,
            // 导入对话框的显示与隐藏
            importDialog: false,
            // 控制已上传用户模板文件列表的显示与隐藏
            showfileList: false,
            filetableData: [],
            importDataDialog: false
        }
    },
    /**
     * @name: 生命周期创建
     * @desc: 页面创建时获取数据
     * @author: lxy
     * @date: 20210908
     */
    created() {
        let self = this;
        if (self.$router.currentRoute.query.params) {
            let params = JSON.parse(decryptCode(self.$router.currentRoute.query.params));
            self.search = params?.search;
            self.page = Number(params?.page);
        }
        self.getData();
    },
    methods:
    {
        /**
         * @name: 获取工作流水列表
         * @author: lxy
         * @date: 2023-01-18
         */
        getData() {
            try {
                let self = this;
                let str = encryptCode({
                    search: self.search,
                    page: self.page,
                    pagesize: self.pageSize
                })
                let data = { sign: request_sign, params: str }
                self.$router.push({
                    path: "/manager/taskDetail",
                    query: {
                        params: str
                    },
                });
                nprogressStart();
                self.$Loading.show();
                API.getTaskDetailList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {

                            self.taskDetailList = result.list
                            self.total = result.total
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
            }
        },
        /**
         * @name: 搜索工作流水
         * @author: lxy
         * @date: 2023-01-18
         */
        searchTaskDetail() {
            this.page = 1;
            this.getData();
        },
        /**
         * @name: 删除工作流水
         * @author: lxy
         * @date: 2023-01-18
         */
        deleteTaskDetail(task_id, id) {
            let self = this;
            let str = encryptCode({
                task_detail_id: id,
            })
            let data = { sign: request_sign, params: str }
            self.$confirm(`确认要删除 ${task_id} 吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                nprogressStart();
                self.$Loading.show();
                API.deleteTaskDetail(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.getData()
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }).catch(() => {
                // self.$Tips({
                //     // 消息提示内容
                //     message: "出错了！",
                //     // 消息提示类型（success-成功,warning-警告/失败）
                //     messageType: 'warning',
                //     displayTime: 1500
                // })   
            });

        },
        /**
         * @name: 导入工作流水
         * @author: lxy
         * @date: 2023-01-18
         */
        importTaskDetail() {
            this.importDialog = true
        },
        /**
         * @name: 下载导入成绩模板
         * @author: camellia
         * @date: 2021-03-08
         */
        downloadImportTemplate() {
            window.open(process.env.VUE_APP_DOMAIN_URL + "/backend/public/uploads/template/任务进度管理导入模板.xlsx");
        },
        /**
        * @name: 上传用户模板文件
        * @author: LXY
        * @date: 2022-05-18
        */
        uploadSuccess(file) {
            this.$refs.upload.clearFiles();
            if (file.code > 0) {
                this.$Tips({
                    // 消息提示内容
                    message: '上传成功',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
                this.importDialog = false;
            }
            else {
            }
        },
        /**
         * @name:读取用户导入文件
         * @author: lxy
         * @date: 2022-04-20
         */
        readFile(file) {
            let self = this
            const fileName = file.name;
            const reader = new FileReader();
            reader.readAsArrayBuffer(file.raw);
            reader.onload = function () {
                const buffer = reader.result;
                const bytes = new Uint8Array(buffer);
                const length = bytes.byteLength;
                let binary = "";
                for (let i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                const XLSX = require("xlsx");
                const wb = XLSX.read(binary, {
                    type: "binary",
                });
                const outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                self.filetableData = []
                outdata.forEach((i) => {
                    //   文件中表格头字段有的是非法字符，作为属性名会报错，这里把属性名改为自定义的
                    // let item = changeObjectkey(i,["personal_id", "name", "major", "college","year"])
                    let item = changeObjectkey(i, ["filled_name", "update_time", "task_id", "content", "work_hours", "progress", "director_name"])
                    self.filetableData.push(item);
                });
            };
            self.importDialog = false;
            self.importDataDialog = true
        },
        /**q
         * @name:确认导入文件
         * @author: lxy
         * @date: 2022-04-20
         */
        confirmImport() {
            let self = this;
            let str = encryptCode({
                list: self.filetableData
            })
            let data = { sign: request_sign, params: str }
            nprogressStart();
            self.$Loading.show();
            API.importTaskDetail(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.importDataDialog = false;
                        if (result.data.length > 0) {
                            self.filetableData = result.data
                            self.failDataDialog = true
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: '全部导入成功',
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        self.page = 1;
                        self.getData();
                    }
                    else {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 工作流水列表表格分页序号递增
         * @author: lxy
         * @date: 2023-01-18
         */
        indexMethod(index) {
            let currentPage = this.page - 0; // 当前页码
            let pageSize = this.pageSize - 0; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
    }
};