<template >
    <div class="taskDetail">
        <div class="logButton clearFix">
          <div class="export">
                <el-button class="themeButton" @click="importTaskDetail">导入</el-button>
            </div>
            <div class="search">
                <div class="searchContent">
                    <el-input placeholder="请输入任务名称" clearable @clear="getData" v-model="search" class="input-with-select" ></el-input>
                </div>
                <el-button class="themeButton" @click="searchTaskDetail">搜索</el-button>
            </div>
        </div>
        <el-card>
            <el-table
                ref="multipleTable"
                :data="taskDetailList"
                tooltip-effect="dark"
                style="width: 100%">
                    <el-table-column
                      type="index"
                      :index="indexMethod"
                      label="序号"
                      width="50">
                    </el-table-column>
                    <el-table-column
                      label="填报人"
                      prop="filled_name"
                      width="100">
                    </el-table-column>
                    <el-table-column
                      label="时间"
                      prop="update_time"
                      width="200">
                    </el-table-column>
                    <el-table-column
                      label="任务id"
                      prop="task_id"
                      width="100">
                    </el-table-column>
                    <el-table-column
                      label="用时"
                      prop="work_hours"
                      width="70">
                    </el-table-column>
                    <el-table-column
                      label="内容"
                      prop="content"
                      min-width="360">
                    </el-table-column>
                    <el-table-column
                      label="进度参考"
                      prop="progress">
                    </el-table-column>
                    <el-table-column
                      label="派发人"
                      prop="director_name">
                    </el-table-column>
                    <el-table-column
                      label="操作" 
                      width="100">
                        <template slot-scope="scope">
                            <div class="operatorItem floatLeft" @click="deleteTaskDetail(scope.row.task_id,scope.row.id)">删除</div>
                        </template>
                    </el-table-column>
            </el-table>
            <el-pagination
                @current-change="getData"
                :current-page.sync="page"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </el-card>
        <el-dialog
          title="导入"
          :visible.sync="importDialog"
          width="30%"
        >
          <span >
            <span class="uploadtipButton" @click="downloadImportTemplate()">点击下载模板</span>
            <div>
              <el-upload
                class="upload-demo"
                :limit="1"
                ref="upload"
                :on-change="readFile"
                :show-file-list="showfileList"
                action=""
                :on-success="uploadSuccess"
              >
                <el-button class="uploadButton">点击上传文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
              </el-upload>
            </div>
          </span>
          <!-- 底部按钮 -->
          <span slot="footer" class="dialog-footer">
            <el-button class="themecancelButton" @click="importDialog = false">关闭</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="数据预览"
          :visible.sync="importDataDialog"
          width="80%"
        >
          <el-table
            ref="multipleTable"
            :data="filetableData"
            tooltip-effect="dark"
            style="width: 100%">
            <el-table-column
              type="index"
              label="序号"
              width="70">
            </el-table-column>
            <el-table-column
              label="填报人"
              prop="filled_name"
              width="100">
            </el-table-column>
            <el-table-column
              label="时间"
              prop="update_time">
            </el-table-column>
            <el-table-column
              label="任务id"
              prop="task_id">
            </el-table-column>
            <el-table-column
              label="用时"
              prop="work_hours"
              min-width="70">
            </el-table-column>
            <el-table-column
              label="内容"
              prop="content"
              min-width="250">
            </el-table-column>
            <el-table-column
              label="进度参考"
              prop="progress">
            </el-table-column>
            <el-table-column
              label="派发人"
              prop="director_name">
            </el-table-column>
          </el-table>
          <!-- 底部按钮 -->
          <span slot="footer" class="dialog-footer">
            <el-button class="themecancelButton" @click="importDataDialog = false">取 消</el-button>
            <el-button class="themeButton" @click="confirmImport">确认导入</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="未成功导入数据"
          :visible.sync="failDataDialog"
          width="80%"
        >
          <el-table
            ref="multipleTable"
            :data="filetableData"
            tooltip-effect="dark"
            style="width: 100%">
            <el-table-column
              type="index"
              label="序号"
              width="70">
            </el-table-column>
            <el-table-column
              label="填报人"
              prop="filled_name"
              width="100">
            </el-table-column>
            <el-table-column
              label="时间"
              prop="update_time">
            </el-table-column>
            <el-table-column
              label="任务id"
              prop="task_id">
            </el-table-column>
            <el-table-column
              label="用时"
              prop="work_hours"
              min-width="70">
            </el-table-column>
            <el-table-column
              label="内容"
              prop="content"
              min-width="250">
            </el-table-column>
            <el-table-column
              label="进度参考"
              prop="progress">
            </el-table-column>
            <el-table-column
              label="派发人"
              prop="director_name">
            </el-table-column>
          </el-table>
          <!-- 底部按钮 -->
          <span slot="footer" class="dialog-footer">
            <el-button class="themecancelButton" @click="failDataDialog = false">关 闭</el-button>
          </span>
        </el-dialog>
    </div>
  </template>
  
  <script>
    import taskDetail from "@/assets/js/manager/task/taskDetail.js";
    export default {
     ...taskDetail
    }
  </script>
  <style>
  .taskDetail .el-table .el-table__cell {
    padding: .55vw 0 !important; 
  }
  .taskDetail .el-icon-check,.taskDetail .el-icon-close{
    font-size: 1vw;
    font-weight: 900;
    text-align: center;
    color: #0D5570;
  }
  .taskDetail .el-dialog {
    border-radius: 0.625vw !important;
  }
  .taskDetail .el-dialog__body{
    padding: 0 20px;
  }
  .taskDetail .el-dialog__body .el-table__body-wrapper{
    max-height: 60vh;
    overflow: scroll;
    scrollbar-width: none;
      -ms-overflow-style: none;
  }
  .taskDetail .el-dialog__body .el-table__body-wrapper::-webkit-scrollbar{
    display: none;
  }
  .taskDetail .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
  }
  </style>
  <style lang="scss" scoped>
    @import "@/assets/css/manager/task/taskDetail.scss";
  </style>